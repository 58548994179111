/* eslint-disable react/no-unescaped-entities */

import React, { useEffect } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import * as CONST from "../CONSTANTS";
import './terms-and-conditions.scss';

// import ReactGA from 'react-ga'

const TermsAndCond = () => {
  useEffect(() => {
    // ReactGA.initialize('UA-115903172-9');
    // ReactGA.pageview(window.location.pathname + window.location.search);
    // ReactGA.event({
    //   category:'event',
    //   action: 'conversion'
    // })
  }, []);

  return (
    <Layout layoutType={CONST.LAYOUT_TCPA_PAGE}>
      <Seo title="Terms and Conditions" />
      <div className="terms-and-conditions-section">
        <div className="container">
          <h4 className="c3" id="h.py4adpkgoij7">
            <span className="c5">WEBSITE TERMS OF USE:</span>
          </h4>
          <p className="c1">
            <span className="c6">
              You are visiting a website owned by Lendmarx, LLC. This Terms of
              Use Agreement (&ldquo;Agreement&rdquo;) applies to all websites
              owned or operated by Lendmarx, LLC, and its affiliates and
              subsidiaries (&ldquo;Websites&rdquo; or each individually
              &ldquo;Website&rdquo;) on which this Agreement or a link thereto
              is displayed. This Agreement also applies to your use of any
              goods, facilities, or services offered through the Websites
              (collectively &ldquo;Services&rdquo;), regardless of where or how
              they are accessed, including but not limited to via a desktop
              computer or mobile device. It is important that you read the
              entire Agreement carefully as some provisions affect your legal
              rights. The Agreement includes an agreement to resolve any
              disputes between you and us through binding arbitration, a class
              action waiver, as well as other important disclaimers, warranties,
              and limitations on liability.
            </span>
          </p>
          <p className="c1">
            <span>
              By accessing, browsing, or using the Websites, you acknowledge
              that you have read, fully understand, and agree to the terms of
              this Agreement, as well as our{" "}
            </span>
            <span className="c0">
              <a
                className="c8"
                href="https://www.healthy-quotes.com/privacy-policy"
                target="_blank" rel="noreferrer"
              >
                Privacy Policy
              </a>
            </span>
            <span>
              , which is incorporated into this Agreement by reference, as well
              as any other policy, agreement, or terms incorporated into this
              Agreement by reference as outlined below.
            </span>
          </p>
          <p className="c1 c14">
            <span className="c6"></span>
          </p>
          <p className="c1">
            <span className="c6">
              These Terms of Use, as currently presented and as updated from
              time to time, incorporate our Privacy Policy. By using the
              Website, submitting an application, or accessing its content, you
              acknowledge and consent to these terms.
            </span>
          </p>
          <p className="c1">
            <span className="c6">
              We retain the right to revise and update these Terms of Use,
              either with or without notice. It&#39;s advisable to periodically
              review the posted terms on the Website. Continued use or access
              after changes will imply your acceptance.
            </span>
          </p>
          <p className="c1">
            <span className="c6">
              Your use of the Website should respect its security and not
              disrupt system resources, services, or networks connected to or
              accessible through it. The Website is intended for lawful
              purposes; any misuse is prohibited.
            </span>
          </p>
          <h4 className="c3" id="h.e0vovgc95lf">
            <span className="c5">Jurisdiction and Age</span>
          </h4>
          <p className="c1">
            <span className="c6">
              If you reside in a jurisdiction that imposes age-related
              restrictions on internet-based applications or the ability to
              enter agreements, and if you fall under the specified age limit,
              you are not permitted to engage in this agreement or utilize the
              Website and its related services. We do not knowingly gather or
              request Personal Information from individuals under 18 years of
              age. If you are under 18, please refrain from attempting to
              register for Services or sharing any Personal Information with us.
              Should we discover that we have inadvertently collected Personal
              Information from a minor under 18, we will promptly delete that
              data. If you suspect that a child under 18 has shared Personal
              Information with us, please get in touch. If we determine that you
              are underage or your use of the Website (or its related services)
              is inappropriate, your profile may be deleted, and access to these
              Terms of Use terminated without prior notice.
            </span>
          </p>
          <h4 className="c3" id="h.9pn3p0gmvos1">
            <span className="c5">Privacy</span>
          </h4>
          <p className="c1">
            <span>
              We are dedicated to upholding the privacy of individuals&#39;
              personal information with whom we engage. Our Privacy Policy
              outlines our policies, practices, and how we manage the
              collection, utilization, and disclosure of your personal data.
              Please refer to our{" "}
            </span>
            <span className="c0">
              <a
                className="c8"
                href="https://www.healthy-quotes.com/privacy-policy"
                target="_blank" rel="noreferrer"
              >
                Privacy Policy
              </a>
            </span>
            <span className="c6">&nbsp;for detailed information.</span>
          </p>
          <p className="c1">
            <span className="c6">
              If you intend to use our services for applying for a specific
              service through our website, you will be requested to provide
              consent for the collection, utilization, and disclosure of the
              personal information you provide. This consent is crucial to
              facilitate the provision of these services.
            </span>
          </p>
          <p className="c1">
            <span className="c6">
              You acknowledge and agree that the Website (and its related
              services) is accessible and usable over the Internet.
              Consequently, your information, including personal data, may be
              transferred internationally, stored, or processed in any country
              worldwide.
            </span>
          </p>
          <h4 className="c3" id="h.mr980gmu4m4o">
            <span className="c5">
              Consent, Communication, Services Paid &amp; Financial Products and
              Services
            </span>
          </h4>
          <p className="c1">
            <span className="c6">
              If you choose to apply for a loan or any other credit-related
              product or service through this website, you are giving your
              consent to us, our affiliated entities, affiliated brands, parent
              companies, related entities, related brands (collectively referred
              to as the &quot;related affiliates&quot;), and/or our third-party
              business affiliates to collect, use, and/or disclose the personal
              information you provide. This information will be used to verify
              your financial status and assess your initial and ongoing
              eligibility for the mentioned credit products and services. By
              agreeing to this, you are also consenting to being contacted by
              us, our related affiliates, and/or our third-party business
              affiliates through methods such as SMS, email, postal mail,
              telephone (including voicemail), or digital messaging to
              facilitate the provision of these products and services.
            </span>
          </p>
          <p className="c1">
            <span className="c6">
              Furthermore, you are also acknowledging your agreement to allow
              us, our related affiliates, and/or our third-party business
              affiliates to conduct a credit check on you based on the
              information you have supplied. This credit check will be carried
              out to verify your financial situation and confirm your
              eligibility for the specified products and services.
            </span>
          </p>
          <h4 className="c3" id="h.e9oi77lon5hq">
            <span className="c5">Further Consent to our TCPA Terms</span>
          </h4>
          <p className="c1">
            <span className="c6">
              By using this website, you are granting your consent to receive
              both informational and marketing communications and messages from
              us and/or our service providers, partners, affiliates, or third
              parties (collectively referred to as &quot;Partners&quot;)
              regarding legal services. These communications may be delivered by
              a live agent, artificial, automated, or pre-recorded voice, as
              well as through email and Short Message Service (&quot;SMS&quot;)
              text messages. They will be directed to your provided residence,
              mobile, or other phone number, using the contact information you
              have previously supplied or may provide in the future (of which
              you confirm ownership, subscription, or regular usage). This
              communication approach encompasses both manual dialing and
              automated telephone dialing systems. It&#39;s important to note
              that standard message and data rates may be applicable to SMS
              messages.
            </span>
          </p>
          <p className="c1">
            <span>
              Your consent extends even if your Contact Info is listed on our,
              or our Partners&#39;, Do Not Call / Do Not Email List, a State or
              National Do Not Call Registry, or any other form of Do Not Contact
              List. You agree to receiving calls from us or our Partners at the
              phone number you have associated with this consent on our webform.
              In the event that you cease using a specific phone number or email
              address provided to us, you agree to promptly inform us of this
              change. It&#39;s essential to understand that granting consent for
              contact, as outlined in this paragraph, is not obligatory for
              purchasing services, and you acknowledge that you have the option
              to withdraw this consent at any time. To revoke this consent, you
              can send an email to{" "}
            </span>
            <span className="c0">
              <a className="c8" href="mailto:unsubscribe@lendmarx.com">
                unsubscribe@lendmarx.com
              </a>
            </span>
            <span>.</span>
          </p>
          <p className="c1">
            <span className="c6">
              Additionally, you acknowledge and accept the possibility of an
              automated message or an artificial or prerecorded voice being
              played when the telephone call is answered, regardless of whether
              the call is picked up by you or another party.
            </span>
          </p>
          <p className="c1">
            <span className="c6">
              You acknowledge and provide your consent for us and/or one or more
              of our Partners to engage in the listening, recording, and
              retention of call recordings between us, our Partners, and you.
              This practice serves quality assurance and other lawful purposes,
              as permitted by applicable laws. No additional notice will be
              given for such activities.
            </span>
          </p>
          <p className="c1">
            <span className="c6">
              Furthermore, you agree to and acknowledge that we may share, sell,
              re-sell, or market your personal data to one or more Partner(s),
              potentially resulting in compensation for us or our Partners. Your
              consent empowers us and our Partners to utilize this data for
              various objectives, including but not limited to re-targeting,
              marketing, analytics, enhancing products and services, and
              improving the overall user experience. This may involve disclosing
              your data to third-party service providers, their brokers, service
              providers, or Partners. You explicitly consent to being contacted
              by us, our agents, representatives, affiliates, third parties,
              Partners, or any entity reaching out for purposes directly
              connected to your inquiry with us. This encompasses inquiries
              related to the product or service for which you are applying, as
              well as any other product or service that we or our Partners
              consider potentially suitable for you, based on the information
              you have submitted to us or our Partners.
            </span>
          </p>
          <p className="c1">
            <span className="c6">
              Your consent extends to the utilization of analytic and marketing
              tools that leverage your data, incorporating Artificial
              Intelligence-based software and technology. Moreover, you grant us
              permission to employ technology capable of tracking your consent
              and capturing screenshots, along with your IP address or any other
              relevant digital traces or evidence. These measures serve to
              document and validate your journey through our web funnel(s) and
              application(s) as evidence of your granted consent.
            </span>
          </p>
          <p className="c1">
            <span className="c6">
              Our services may encompass sending SMS messages, which could
              involve delivering up to five messages per day to your wireless
              device. We might utilize your information to reach out to you
              regarding your details, purchases, Website updates, surveys, or
              communications of an informational and service-oriented nature.
              This includes important security updates. If you wish to
              discontinue receiving these messages, you can opt out at any time
              by responding with &quot;STOP&quot; to the received SMS message.
              Once you send the message &quot;STOP,&quot; we will respond with
              an SMS message confirming your successful unsubscription.
              Following this confirmation, you will no longer receive SMS
              messages from us. If you decide to rejoin, you can simply sign up
              as you did initially, and we will resume sending SMS messages to
              you. For assistance, please respond to a text with
              &quot;HELP.&quot;
            </span>
          </p>
          <p className="c1">
            <span className="c6">
              Please note that standard message and data rates may apply,
              depending on your cell phone plan. Carriers are not responsible
              for messages that are delayed or undelivered. Alternatively, if
              you prefer, you can submit a request to cease SMS message delivery
              by emailing us. In your email, please include the email address
              and phone number that you used for registration.
            </span>
          </p>
          <p className="c1">
            <span className="c6">
              Additionally, your consent is granted for us and/or one or more of
              our Partners to conduct a credit check on you based on the
              information you have provided.
            </span>
          </p>
          <h4 className="c3" id="h.z83iv6ge7tdg">
            <span className="c5">User Accounts</span>
          </h4>
          <p className="c1">
            <span className="c6">
              When it&#39;s necessary to create an account for utilizing aspects
              of the Website (or any associated services), you must finalize the
              registration process by furnishing accurate and comprehensive
              information as requested on the registration form. During this
              process, you will be prompted to select a username and password.
            </span>
          </p>
          <p className="c1">
            <span className="c6">
              In cases where we offer the option for you to choose your own
              username(s) in connection with the Website (or its related
              services), we will make reasonable efforts to assign the requested
              name(s) to you upon your request. However, it&#39;s important to
              note that we retain the right to decline any name selection that
              (i) has already been allocated, or (ii) we determine, at our sole
              discretion, to be offensive, in violation of applicable laws, an
              attempt to impersonate another individual, or likely to interfere
              with, violate, exploit, or infringe upon the rights of another. In
              instances where a requested name is rejected, we may, at our sole
              discretion, offer an alternative name, request you to choose
              another name, or decide not to proceed further.
            </span>
          </p>
          <p className="c1">
            <span className="c6">
              You acknowledge your responsibility for maintaining the
              confidentiality of your chosen username and password. We and any
              of our partners, affiliates, officers, directors, employees,
              consultants, agents, and representatives will not be held liable
              for any losses incurred due to unauthorized use of your login
              credentials or the theft, loss, or misappropriation of your
              username or password.
            </span>
          </p>
          <p className="c1">
            <span className="c6">
              Using the account, username, or password of another individual is
              strictly prohibited unless you have explicit authorization from
              both the other user and us. Should you become aware of any
              unauthorized use, theft, or misappropriation of your account,
              username, or password, you agree to promptly inform us. We shall
              not be held responsible for any losses resulting from the
              unauthorized usage of your username or password, whether conducted
              with or without your knowledge.
            </span>
          </p>
          <p className="c1">
            <span className="c6">
              You acknowledge and agree that you bear responsibility for any and
              all access, use, or engagement with the Website (and its related
              services) that occurs in conjunction with your passwords and user
              identifications. Furthermore, we are authorized to treat the user
              identifications and passwords as conclusive evidence that you have
              accessed, utilized, or engaged with the Website (or any of its
              related services).
            </span>
          </p>
          <h4 className="c3" id="h.62mvhhl50asm">
            <span className="c5">Restrictions on Use of the Website</span>
          </h4>
          <p className="c1">
            <span className="c6">
              Your use of the Website must strictly adhere to lawful purposes
              and be in alignment with these Terms. It is agreed that you shall
              not employ the Website in the following ways:
            </span>
          </p>
          <ul className="c7 lst-kix_mrmjkncs3qcn-0 start">
            <li className="c2 li-bullet-0">
              <span className="c4">
                In any manner that contravenes applicable federal, state, local,
                or international laws or regulations, including those concerning
                data or software export to and from the US or other countries.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                &bull; With the intention of exploiting, causing harm to, or
                attempting to exploit or harm minors, whether by exposing them
                to unsuitable content, soliciting personally identifiable
                information, or through any other means.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                To transmit or solicit the transmission of any advertising or
                promotional material without our prior written consent,
                including but not limited to &quot;junk mail,&quot; &quot;chain
                letters,&quot; &quot;spam,&quot; or any similar form of
                solicitation.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                To impersonate or make an attempt to impersonate us, our
                personnel, another user, or any other individual or entity,
                which includes the usage of email addresses or screen names
                linked with any of the aforementioned.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                In any manner that could potentially disable, overload, damage,
                or impair the functioning of the Website or disrupt any third
                party&#39;s utilization of the Website, including their ability
                to engage in real-time activities on the platform.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                To engage in any form of conduct that curtails or hampers the
                usage or enjoyment of the Website by any individual, or which,
                as determined by us, might inflict harm upon us or the
                Website&#39;s users, potentially exposing them to legal
                liabilities.
              </span>
            </li>
          </ul>
          <p className="c1">
            <span className="c6">
              You are prohibited from engaging in the following activities in
              relation to the Website:
            </span>
          </p>
          <ul className="c7 lst-kix_eywatyow8256-0 start">
            <li className="c2 li-bullet-0">
              <span className="c4">
                Employing any robot, spider, or automated mechanism to access
                the Website, whether for monitoring or replicating any of the
                Website&#39;s content.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Utilizing any device, software, or routine that hampers the
                seamless functioning of the Website.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Introducing any form of malicious or technologically harmful
                material, such as viruses, Trojan horses, worms, logic bombs, or
                similar entities.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Making any unauthorized attempts to access, interfere with,
                damage, or disrupt any segments of the Website, the server
                hosting the Website, or any interconnected server, computer, or
                database.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Launching attacks against the Website, including
                denial-of-service attacks or distributed denial-of-service
                attacks.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                Undertaking any other efforts to disrupt the proper operation of
                the Website.
              </span>
            </li>
          </ul>
          <h4 className="c3" id="h.7bz63pjfmuqa">
            <span className="c5">
              User Feedback; Third-Party Social Media Postings
            </span>
          </h4>
          <p className="c1">
            <span className="c6">
              Upon submitting any ideas or suggestions to us, whether through
              our Website, social media platforms, other digital channels,
              Google, review sites, or any other means, in connection with
              enhancing the Website or Services (collectively referred to as
              &quot;Feedback&quot;), you hereby acknowledge and agree that such
              Feedback will be considered our property. By doing so, you are
              also consenting to assign to us, without compensation or
              additional notice, all rights, title, and interest, including
              intellectual property and moral rights, associated with the
              Feedback. We shall possess unrestricted rights to utilize the
              Feedback for any purpose, whether commercial or otherwise, without
              any obligation to compensate you or provide further notification.
              Please note that no obligations of confidentiality shall apply to
              the Feedback, and we will not be held accountable for any
              utilization or disclosure of the Feedback.
            </span>
          </p>
          <h4 className="c3" id="h.fs3zws3r0v58">
            <span className="c5">
              Cooperation with Law Enforcement and Government Agencies; Required
              Disclosures
            </span>
          </h4>
          <p className="c1">
            <span className="c6">
              You recognize that we possess the authority to thoroughly
              investigate and prosecute any breaches of these Terms,
              encompassing violations related to intellectual property, privacy
              rights, publicity, and website security matters, to the maximum
              extent allowable by law. In pursuing such actions, we reserve the
              right to collaborate and engage with law enforcement entities to
              bring legal action against users who contravene these Terms. While
              we hold no obligation to constantly monitor your access to the
              Website or the Services, we retain the prerogative to do so. This
              monitoring might be conducted to manage the operation of the
              Website, ascertain your adherence to these Terms, or satisfy legal
              requirements, court orders, administrative mandates, or requests
              from governmental or regulatory bodies.
            </span>
          </p>
          <p className="c1">
            <span className="c6">
              You acknowledge and consent to the potential disclosure of your
              personal information if mandated by law, a court order, legal
              proceedings, or a subpoena. This includes instances where we must
              respond to governmental or regulatory inquiries. Should such
              circumstances arise, we will strive to provide you with reasonable
              notice and the opportunity to seek protective measures (at your
              expense), if permissible. Furthermore, we may take such action if
              we deem it necessary to: (a) comply with legal obligations,
              address legal processes served upon us, investigate or prevent
              potential unlawful activities; (b) uphold these Terms (including
              billing and collection matters), mitigate liability, safeguard
              against third-party claims, support government enforcement
              agencies, or fortify the security and integrity of our Website; or
              (c) protect the rights, assets, or well-being of us, our users, or
              any other parties involved.
            </span>
          </p>
          <h4 className="c3" id="h.keoq0swhkmy2">
            <span className="c5">Security of Website</span>
          </h4>
          <p className="c1">
            <span className="c6">
              You commit not to engage in, nor allow any third party to engage
              in, the unauthorized entry of restricted sections within our
              computer systems or undertake actions that fall outside the scope
              of authorization as stipulated by these Terms of Use. We hold the
              authority, without prior notification, to temporarily suspend your
              access to the Website (as well as any of its affiliated services),
              which may encompass specific sections housed within the Website.
              This suspension could involve deactivating passwords or internet
              links if we possess a reasonable belief that you, or any other
              parties, are gaining unauthorized access to our other systems or
              information, or are employing valid user credentials or passwords
              in an unauthorized manner. The duration of these suspensions will
              be determined by us based on a reasonable assessment of the
              necessary time for a comprehensive investigation into the
              suspended activity.
            </span>
          </p>
          <h4 className="c3" id="h.7w1g5goc6e60">
            <span className="c5">Intellectual Property</span>
          </h4>
          <p className="c1">
            <span className="c6">
              The content on the Website, including how it&#39;s organized and
              presented (referred to as &quot;Materials&quot;), is owned by our
              site and its licensors, and may be safeguarded by intellectual
              property laws, encompassing copyright, trademarks, trade names,
              internet domain names, and similar rights.
            </span>
          </p>
          <p className="c1">
            <span className="c6">
              You are permitted to use and reproduce the Materials exclusively
              for your personal, non-commercial purposes, as long as the
              Materials remain unaltered, and copyright along with other
              intellectual property notices remain intact. Generating derivative
              works from or exploiting the Materials in any manner is
              prohibited. Unless you have a separate agreement with us, any use
              of these Materials beyond what&#39;s mentioned above requires
              written permission from us.
            </span>
          </p>
          <h4 className="c3" id="h.fxmpwgqcfgvd">
            <span className="c5">Currency of website</span>
          </h4>
          <p className="c1">
            <span className="c6">
              We regularly refresh the information on the Website. Nevertheless,
              we cannot assure or assume responsibility for the accuracy,
              current status, or comprehensiveness of the information found on
              the Website. We hold the right to amend, enhance, or remove
              information, services, and/or resources within the Website, and
              retain the authority to do so without prior notice to prior,
              present, or potential visitors.
            </span>
          </p>
          <h4 className="c3" id="h.nve71ifscnqg">
            <span className="c5">Linked websites</span>
          </h4>
          <p className="c1">
            <span className="c6">
              The Website might offer links to third-party websites solely for
              your convenience. The presence of these links does not indicate
              our monitoring or endorsement of these websites. We do not assume
              any responsibility for such external websites. We are not
              accountable or responsible, either directly or indirectly, for any
              damage or loss purportedly caused by or related to the utilization
              of or reliance on any information, content, products, or services
              accessible through third-party websites or linked resources.
            </span>
          </p>
          <h4 className="c3" id="h.9x3k67z12zrk">
            <span className="c5">Internet Viruses</span>
          </h4>
          <p className="c1">
            <span className="c6">
              Owing to potential technical challenges with the Internet, issues
              with Internet software or transmission could result in inaccurate
              or incomplete reproductions of the information found on the
              Website. We disclaim any responsibility or liability for software,
              computer viruses, or any other harmful or disruptive files or
              programs that might corrupt or negatively affect your computer
              equipment or other belongings due to your interaction with the
              Website, your use of it, or your downloading of any Materials from
              the Website. We suggest that you install suitable anti-virus or
              protective software.
            </span>
          </p>
          <h4 className="c3" id="h.hm5u9skefoly">
            <span className="c5">
              Website and Materials Provided &quot;As Is&quot;
            </span>
          </h4>
          <p className="c1">
            <span className="c6">
              THE WEBSITE AND THE MATERIALS ARE OFFERED &quot;AS IS,&quot;
              &quot;AS AVAILABLE,&quot; AND &quot;WITH ALL FAULTS,&quot; WITHOUT
              ANY FORM OF REPRESENTATION, WARRANTY, OR CONDITION, WHETHER
              EXPRESS OR IMPLIED. WE CLEARLY DISCLAIM ALL WARRANTIES, INCLUDING
              IMPLIED REPRESENTATIONS, WARRANTIES, OR CONDITIONS OF
              MERCHANTABILITY, FITNESS FOR A SPECIFIC PURPOSE, TITLE, AND
              NON-INFRINGEMENT. WE DO NOT ASSERT OR GUARANTEE THAT THE WEBSITE
              OR THE MATERIALS WILL SATISFY YOUR NEEDS OR THAT THEIR USAGE WILL
              BE CONTINUOUS OR FREE FROM ERRORS.
            </span>
          </p>
          <h4 className="c3" id="h.fphhaxn7ms5j">
            <span className="c5">Limitation of liability</span>
          </h4>
          <p className="c1">
            <span className="c6">
              YOU BEAR ALL RESPONSIBILITY AND RISK FOR THE USE OF THE WEBSITE
              AND THE MATERIALS, INCLUDING ANY INFORMATION CONTAINED THEREIN.
            </span>
          </p>
          <p className="c1">
            <span className="c6">
              UNDER NO CIRCUMSTANCES SHALL WE, OUR DIRECTORS, OFFICERS,
              EMPLOYEES, SHAREHOLDERS, PARTNERS, OR AGENTS BE LIABLE FOR ANY
              INCIDENTAL, INDIRECT, PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL
              DAMAGES OF ANY KIND (INCLUDING DAMAGES FOR LOSS OF PROFITS,
              INTERRUPTION, LOSS OF BUSINESS INFORMATION, OR ANY OTHER FINANCIAL
              LOSS) ARISING FROM ANY CLAIM, LOSS, DAMAGE, ACTION, SUIT, OR OTHER
              PROCEEDING RESULTING FROM OR RELATED TO THESE TERMS OF USE. THIS
              INCLUDES YOUR USE OF, RELIANCE ON, ACCESS TO, OR UTILIZATION OF
              THE WEBSITE, THE MATERIALS, OR ANY PART THEREOF, OR ANY RIGHTS
              GRANTED TO YOU HEREUNDER. THIS APPLIES EVEN IF WE HAVE BEEN
              ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SUCH ACTIONS MAY ARISE
              FROM CONTRACT, TORT (INCLUDING NEGLIGENCE), INFRINGEMENT OF
              INTELLECTUAL PROPERTY RIGHTS, OR OTHER LEGAL GROUNDS.
            </span>
          </p>
          <p className="c1">
            <span className="c6">
              IN ANY EVENT, OUR TOTAL MAXIMUM AGGREGATE LIABILITY UNDER THESE
              TERMS OF USE OR THE USE OR EXPLOITATION OF ANY OR ALL PARTS OF THE
              WEBSITE OR THE MATERIALS SHALL BE CAPPED AT ONE HUNDRED ($100.00)
              DOLLARS.
            </span>
          </p>
          <p className="c1">
            <span className="c6">
              CERTAIN JURISDICTIONS MAY NOT ALLOW FOR THE LIMITATION OR
              EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. AS
              A RESULT, THE AFOREMENTIONED LIMITATION OR EXCLUSION MAY NOT BE
              APPLICABLE TO YOU.
            </span>
          </p>
          <h4 className="c3" id="h.cgy3t6c8nqu2">
            <span className="c5">Indemnification</span>
          </h4>
          <p className="c1">
            <span className="c6">
              You agree to hold us, along with our officers, directors,
              managers, employees, partners, affiliates, and service providers,
              harmless from any claims or liability that arise from or are
              connected to any breach of these Terms of Use by you or anyone
              acting on your behalf.
            </span>
          </p>
          <h4 className="c3" id="h.u5nel9b00qnl">
            <span className="c5">Contact us</span>
          </h4>
          <p className="c1">
            <span className="c6">
              If you opt to email us, please be aware that the Internet is not a
              secure medium, and any communication sent via email could be lost,
              intercepted, or modified. We are not responsible for any damages
              arising from communications to or from us. In regard to any
              information you provide to us, you acknowledge that:
            </span>
          </p>
          <ul className="c7 lst-kix_xj32p8blht8s-0 start">
            <li className="c2 li-bullet-0">
              <span className="c4">
                We hold no obligation regarding such information.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">The information is not confidential.</span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                We may utilize, disclose, distribute, or reproduce the
                information and may incorporate any ideas, concepts, or
                expertise contained in the information, in accordance with our
                Privacy Policy.
              </span>
            </li>
            <li className="c2 li-bullet-0">
              <span className="c4">
                The information is accurate, and its disclosure does not
                infringe upon the legal rights of others.
              </span>
            </li>
          </ul>
          <h4 className="c3" id="h.gvkok526qv76">
            <span className="c5">Applicable law</span>
          </h4>
          <p className="c1">
            <span>
              This Agreement, and any disputes arising out of or related to this
              Agreement or the Websites, Services, or Content, is governed by
              the laws of the State of Florida without regard to any conflicts
              of laws provisions. If any matter proceeds in court, including
              post-arbitration confirmation proceedings, you agree to the
              exclusive personal jurisdiction by, and venue in, the state and
              federal courts located in St. Johns County, Florida and you waive
              any objection to such jurisdiction or venu
            </span>
            <span className="c12">e.</span>
          </p>
          <h4 className="c3" id="h.5uzl7d4mbgam">
            <span className="c5">Disputes; Resolution</span>
          </h4>
          <p className="c1">
            <span className="c6">
              Time Constraint: Any claim or legal action against us must be
              initiated within a period of twelve (12) months from the
              occurrence of the underlying cause; otherwise, such claim or
              action will be permanently prohibited.
            </span>
          </p>
          <h4 className="c3" id="h.rv4b5yq5h5da">
            <span className="c5">MANDATORY BINDING ARBITRATION</span>
          </h4>
          <p className="c1">
            <span className="c6">
              Dispute Resolution: Except as outlined in the section titled
              &quot;Exemptions from Agreement to Arbitrate,&quot; in the event
              of any dispute, claim, question, or disagreement arising from or
              connected to the Terms or their violation, or the use of the
              Website or our services, both parties will make reasonable efforts
              to settle the matter. The parties will engage in consultative and
              good faith negotiations, aiming to arrive at a fair and equitable
              solution that satisfies both parties&#39; interests. If a
              resolution isn&#39;t achieved within thirty (30) days, either
              party may provide notice to the other, triggering binding
              arbitration in accordance with the Commercial Rules
              (&quot;Rules&quot;) of the International Arbitration Association
              (the &quot;IAA&quot;), subject to the terms below. This
              arbitration agreement is enforceable under the prevailing law of
              any relevant jurisdiction. Notice of arbitration must be filed in
              writing with the other party and the IAA. Such notice must be
              filed within a reasonable timeframe after the dispute arises, and
              not beyond the date when legal proceedings based on the dispute
              would be barred by applicable statutes of limitations.
            </span>
          </p>
          <p className="c1">
            <span className="c6">
              The parties concur that a single arbitrator will preside over the
              dispute. The arbitrator will be chosen through mutual agreement of
              the parties, or by IAA selection if an agreement isn&#39;t reached
              within twenty (20) days of the notice of arbitration. The
              arbitrator&#39;s decision will be rendered in writing and deemed
              final. It may be entered as judgment in any court with
              jurisdiction, and subject only to vacation, modification, or
              appeal as allowed by relevant law, as agreed by the parties. The
              expenses, including arbitrator fees and IAA charges, will be
              shared equally by both parties.
            </span>
          </p>
          <p className="c1">
            <span className="c6">
              The arbitrator holds no authority to award attorney&#39;s fees,
              punitive damages, or any monetary relief beyond the prevailing
              party&#39;s actual damages. Each party expressly waives any claim
              to such relief. The award may include equitable remedies. The
              arbitrator will ensure that all decisions align with the Terms.
              The arbitrator may provide a summary judgment on all or some
              issues, provided the responding party has a fair opportunity to
              respond.
            </span>
          </p>
          <p className="c1">
            <span className="c6">
              The parties agree to maintain the confidentiality of all
              arbitration-related matters as per the IAA Rules. Prior to
              permissible disclosure under the Rules, a party shall notify the
              other party in writing, offering a reasonable chance to protect
              their interests. Additionally, the arbitrator&#39;s award may be
              entered in a court of competent jurisdiction.
            </span>
          </p>
          <h4 className="c3" id="h.x5ahgfxc4mtz">
            <span className="c5">Exceptions to Agreement to Arbitrate</span>
          </h4>
          <p className="c1">
            <span className="c6">
              Both you and we acknowledge the option to bypass arbitration and
              directly pursue resolution through the court system for disputes
              concerning: (a) intellectual property matters such as trademarks,
              trade dress, domain names, trade secrets, copyrights, or patents
              owned by you or us, or (b) instances where you have violated our
              intellectual property rights.
            </span>
          </p>
          <h4 className="c3" id="h.79hobzaxvsu2">
            <span className="c5">Class Action Waiver</span>
          </h4>
          <p className="c1">
            <span className="c6">
              All efforts to resolve or litigate disputes in any forum shall be
              exclusively pursued on an individual level. You and we mutually
              agree not to pursue any dispute as a class action or through any
              process where either party acts or intends to act as a
              representative for others. Each party hereby renounces the right
              to assert combined claims in relation to any disputes governed by
              arbitration under these Terms or any conflicts between the
              parties. No arbitration or proceeding will be consolidated with
              another without the prior written agreement of all parties
              involved in the relevant arbitrations or proceedings.
            </span>
          </p>
          <h4 className="c3" id="h.9zo6evpsfb0v">
            <span className="c5">Waiver of Jury Trial</span>
          </h4>
          <p className="c1">
            <span className="c6">
              Each party irrevocably and unconditionally waives any right we or
              you may have to a trial by jury for any legal action arising out
              of or relating to these Terms or the transactions contemplated
              hereby.
            </span>
          </p>
          <h4 className="c3" id="h.ya39z7f07m6m">
            <span className="c5">Assignment</span>
          </h4>
          <p className="c1">
            <span className="c6">
              You are prohibited from transferring, conveying, or assigning
              (referred to collectively as &quot;assign&quot; or its variations)
              these Terms, whether in part or in full, without obtaining our
              prior written consent, which we may grant or deny at our sole
              discretion. Any attempt to assign in violation of these Terms will
              be null and void. We retain the freedom to assign these Terms at
              our discretion without prior notice. Despite the above, these
              Terms will remain binding and beneficial to the authorized
              successors and assignees of each party. We maintain the right, and
              you hereby provide your consent, to reveal, transfer, and/or
              assign your personal information in connection with actions such
              as mergers, consolidations, restructurings, sales, financings, or
              other transactions, as well as pursuant to court proceedings.
              Additionally, if a potential buyer expresses interest in acquiring
              us, you agree that we may confidentially share your personal
              information with the potential buyer, subject to the usage
              limitations outlined in these Terms.
            </span>
          </p>
          <h4 className="c3" id="h.z92p0gevtsk8">
            <span className="c5">General</span>
          </h4>
          <p className="c1">
            <span className="c6">
              Our failure to insist on or enforce strict adherence to any
              provision within these Terms of Use should not be interpreted as a
              waiver of that provision or right.
            </span>
          </p>
          <p className="c1">
            <span className="c6">
              In the event that any provision or portion of these Terms of Use
              is deemed entirely or partially unenforceable, the parties, or if
              mutual agreement is unattainable, a court of competent
              jurisdiction, shall establish a valid provision or provisions, or
              portion thereof, that closely mirrors the intentions of the
              unenforceable provision or part.
            </span>
          </p>
          <p className="c1">
            <span className="c6">
              Both parties have stipulated that these Terms of Use and all
              associated documents be composed in the English language.
            </span>
          </p>
          <h4 className="c3" id="h.nwslwwu5zft">
            <span className="c5">Communications</span>
          </h4>
          <p className="c1">
            <span className="c6">
              Upon creating a user account or providing us with any contact or
              relevant information, you hereby agree and provide consent to
              receive communications via mail and electronic means (including
              email, text/SMS, and telephone) from us. These communications may
              also be made available on the Website, such as through notices
              posted on your account profile page, and pertain to information
              and/or our services (collectively referred to as
              &quot;Communications&quot;). For users holding an account,
              Communications may include those mandated by law, such as
              notifications about data security incidents, pertaining to us,
              your account, your information, the Website, or the Services
              (&quot;Required Communications&quot;). Additionally,
              Communications may include those we send to you for other
              purposes. If you wish to modify the email or mobile phone number
              associated with your account, you can do so by accessing your
              account profile page or by reaching out to us directly. You have
              the option to decline all Communications, except Required
              Communications, via email by providing us with a notice that
              includes your full name, user name, and email address. However, by
              opting out, you will no longer receive any further electronic
              notifications from us (excluding Required Communications), which
              could encompass important notices or announcements.
            </span>
          </p>
          <h4 className="c3" id="h.s4mmp3q790ok">
            <span className="c5">Contact Us</span>
          </h4>
          <p className="c1">
            <span>Feel free to contact us at: </span>
            <span className="c0">
              <a className="c8" href="mailto:info@lendmarx.com">
                info@lendmarx.com
              </a>
            </span>
          </p>
          <p className="c1 mt-5">
            <span>Last Updated 10/15/2024</span>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default TermsAndCond;
